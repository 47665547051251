var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('loading'):_c('div',{staticClass:"email-setting"},[_c('validation-observer',{ref:"emailSettingform",staticClass:"w-100"},[_c('b-form',[_c('b-row',{staticClass:"mx-1"},[_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("g.settings/emailSettings"))+" ")])]),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/drivername'),"label-for":"driver"}},[_c('validation-provider',{attrs:{"name":"driver"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.canEdit,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.mail_driver),callback:function ($$v) {_vm.$set(_vm.form_data, "mail_driver", $$v)},expression:"form_data.mail_driver"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/host'),"label-for":"host"}},[_c('validation-provider',{attrs:{"name":"host"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.canEdit,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.mail_host),callback:function ($$v) {_vm.$set(_vm.form_data, "mail_host", $$v)},expression:"form_data.mail_host"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/port'),"label-for":"port"}},[_c('validation-provider',{attrs:{"name":"port"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.canEdit,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.mail_port),callback:function ($$v) {_vm.$set(_vm.form_data, "mail_port", $$v)},expression:"form_data.mail_port"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/FormAddress'),"label-for":"FormAddress"}},[_c('validation-provider',{attrs:{"name":"FormAddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.canEdit,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.mail_from_address),callback:function ($$v) {_vm.$set(_vm.form_data, "mail_from_address", $$v)},expression:"form_data.mail_from_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/fromName'),"label-for":"fromName"}},[_c('validation-provider',{attrs:{"name":"fromName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.canEdit,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.mail_username),callback:function ($$v) {_vm.$set(_vm.form_data, "mail_username", $$v)},expression:"form_data.mail_username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/Encryption'),"label-for":"Encryption"}},[_c('validation-provider',{attrs:{"name":"Encryption"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.canEdit,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.mail_encryption),callback:function ($$v) {_vm.$set(_vm.form_data, "mail_encryption", $$v)},expression:"form_data.mail_encryption"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/userName'),"label-for":"userName"}},[_c('validation-provider',{attrs:{"name":"userName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.canEdit,"state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.mail_from_name),callback:function ($$v) {_vm.$set(_vm.form_data, "mail_from_name", $$v)},expression:"form_data.mail_from_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.settings/generalSettings/password'),"label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.canEdit,"type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.mail_password),callback:function ($$v) {_vm.$set(_vm.form_data, "mail_password", $$v)},expression:"form_data.mail_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"actions"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.buttonBusy,"rounded":"","opacity":"0.7","spinner-small":"","spinner-variant":"primary"}},[(_vm.canEdit)?_c('b-button',{staticClass:"p-1",attrs:{"variant":"primary"},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.$t("g.settings/generalSettings/submitButton"))+" ")]):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }