<template>
  <loading v-if="isLoading" />
  <div class="email-setting" v-else>
    <validation-observer ref="emailSettingform" class="w-100">
      <b-form>
        <b-row class="mx-1">
          <b-col md="12" class="mb-1">
            <h2>
              {{ $t("g.settings/emailSettings") }}
            </h2>
          </b-col>
          <!-- driver name  -->
          <b-col cols="12" lg="6" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/drivername')"
              label-for="driver"
            >
              <validation-provider #default="{ errors }" name="driver">
                <b-form-input
                  :disabled="!canEdit"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.mail_driver"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **driver name  -->
          <!-- Host  -->
          <b-col cols="12" lg="6" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/host')"
              label-for="host"
            >
              <validation-provider #default="{ errors }" name="host">
                <b-form-input
                  :disabled="!canEdit"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.mail_host"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **host   -->
          <!-- port  -->
          <b-col cols="12" lg="6" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/port')"
              label-for="port"
            >
              <validation-provider #default="{ errors }" name="port">
                <b-form-input
                  :disabled="!canEdit"
                  v-model="form_data.mail_port"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **port   -->
          <!-- FormAddress  -->
          <b-col cols="12" lg="6" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/FormAddress')"
              label-for="FormAddress"
            >
              <validation-provider #default="{ errors }" name="FormAddress">
                <b-form-input
                  :disabled="!canEdit"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.mail_from_address"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **FormAddress   -->
          <!-- fromName  -->
          <b-col cols="12" lg="6" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/fromName')"
              label-for="fromName"
            >
              <validation-provider #default="{ errors }" name="fromName">
                <b-form-input
                  :disabled="!canEdit"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.mail_username"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **fromName   -->
          <!-- Encryption  -->
          <b-col cols="12" lg="6" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/Encryption')"
              label-for="Encryption"
            >
              <validation-provider #default="{ errors }" name="Encryption">
                <b-form-input
                  :disabled="!canEdit"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.mail_encryption"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **Encryption   -->
          <!-- userName  -->
          <b-col cols="12" lg="6" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/userName')"
              label-for="userName"
            >
              <validation-provider #default="{ errors }" name="userName">
                <b-form-input
                  :disabled="!canEdit"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.mail_from_name"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **userName   -->
          <!-- password  -->
          <b-col cols="12" lg="6" class="mb-1">
            <b-form-group
              :label="$t('g.settings/generalSettings/password')"
              label-for="password"
            >
              <validation-provider #default="{ errors }" name="password">
                <b-form-input
                  :disabled="!canEdit"
                  type="password"
                  :state="errors.length > 0 ? false : null"
                  v-model="form_data.mail_password"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- **password   -->

          <b-col class="actions">
            <b-overlay
              :show="buttonBusy"
              rounded
              opacity="0.7"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                v-if="canEdit"
                variant="primary"
                class="p-1"
                @click="submitForm"
              >
                {{ $t("g.settings/generalSettings/submitButton") }}
              </b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormFile,
  BRow,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { integer, required } from "vee-validate/dist/rules";
import loading from "@/components/shared/loading/loading.vue";
export default {
  name: "Settings",
  components: {
    BCol,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BForm,
    BFormFile,
    BRow,
    BButton,
    loading,
    BOverlay,
  },
  data() {
    return {
      form_data: {
        mail_driver: null,
        mail_host: null,
        mail_port: null,
        mail_username: null,
        mail_password: null,
        mail_encryption: null,
        mail_from_name: null,
        mail_from_address: null,
      },
      isLoading: true,
      buttonBusy: false,
      archive: {},
    };
  },
  created() {
    this.getData();
  },
  computed: {
    canEdit() {
      return (
        this.globalPermission.settings && this.globalPermission.settings.update
      );
    },
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const response = await this.$http.get("admin/settings");
        if (response.status == 200) {
          const wantedKeys = Object.keys(this.form_data);
          response.data.data.map((ele) => {
            if (wantedKeys.includes(ele.key)) {
              this.form_data[ele.key] = ele.value.value;
              this.archive[ele.key] = ele.value.value;
            }
          });
        }
      } catch (err) {
        this.isLoading = false;
        this.$helpers.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    async submitForm() {
      this.buttonBusy = true;
      try {
        const validatetion = this.$refs.emailSettingform.validate();
        if (validatetion) {
          const arrToEdit = [];
          for (let item in this.form_data) {
            if (this.archive[item] != this.form_data[item]) {
              arrToEdit.push({ key: item, value: this.form_data[item] });
            }
          }
          arrToEdit.map(async (ele) => {
            const formData = new FormData();
            formData.append("value", ele.value);
            const request = await this.$http.post(
              `admin/settings/${ele.key}?_method=put`,
              formData
            );
          });
        }
      } catch (errors) {
        this.buttonBusy = false;
        this.$helpers.handleError(errors);
      } finally {
        this.buttonBusy = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
